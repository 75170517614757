import React from 'react';

const CallToAction = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Don’t Miss Out on This Exclusive Opportunity!
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-24 bg-transparent text-gray-700">
                        <p className="text-lg leading-relaxed">
                            Apply today and join us at the <strong>Capital Fundraising Summit</strong> to unlock your unicorn’s full potential.

                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CallToAction;
