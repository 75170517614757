import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Global Recognition
                                </h3>
                                <p>
                                Gain visibility as a leading royal family office dedicated to shaping the future through carefully chosen investments that make a difference worldwide.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                High-Impact Networking
                                </h3>
                                <p>
                                Engage with renowned family offices, venture capitalists, and sovereign wealth funds who share your commitment to driving progress and innovation.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Access to Elite Investment Opportunities
                                </h3>
                                <p>
                                Position your family office as a partner for high-impact ventures, collaborating with pioneering leaders and innovators to build long-lasting value.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Support for Long-Term Growth Initiatives
                                </h3>
                                <p>
                                Explore new avenues for impactful investment that align with your family’s mission and legacy while supporting forward-looking projects that address global challenges.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Credibility with Top Investors
                                </h3>
                                <p>
                                Your involvement at this summit signals credibility and dedication to strategic, values-based investment, attracting elite opportunities to expand your legacy.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Validation from Industry Leaders
                                </h3>
                                <p>
                                Participation reinforces your family office's commitment to advancing impactful projects that align with royal family values, setting a benchmark for others in the sector.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Strategic Networking with Global Leaders
                                </h3>
                                <p>
                                Connect with high-profile stakeholders, gaining insights and forming partnerships that will drive your family’s mission forward for generations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
