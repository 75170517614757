import React from 'react';
import backgroundImage from '../../Image/preview-7.webp';

const Introduction = () => {
  return (
    <section className="flex flex-col md:flex-row w-full h-96 bg-cover bg-center rounded-lg mb-24 shadow-2xl mt-20 mx-auto max-w-5xl"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      
      <div className="flex-1 hidden md:block" />
      <div className="flex-1 bg-gradient-to-r from-transparent to-orange-700 flex flex-col justify-center p-6 rounded-r-lg text-2xl">
        <p className="leading-relaxed font-spartan font-bold text-white">
        Are you representing a sovereign wealth fund looking to amplify national development and secure high-yield global investments?
        </p>
        <p className="text-white font-spartan font-bold mt-2">
          The <strong className=''>(CFS)</strong> platform connects you with decision-makers, high-potential startups, and strategic partners that align with your long-term vision for growth and stability.
        </p>
      </div>
    </section>
  );
};

export default Introduction;
