import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Access to High-Value Investment Opportunities
                                </h3>
                                <p>
                                CFS connects you directly with family offices, sovereign wealth funds, and institutional investors seeking dynamic private equity portfolios and game-changing growth strategies.

                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Build Credibility Among Top Investors
                                </h3>
                                <p>
                                Presenting at CFS endorses your fund’s market expertise, attracting potential partners and investors who value a strategic, impactful approach.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Global Networking and Deal Flow
                                </h3>
                                <p>
                                Engage with influential fund managers, corporate leaders, and policymakers to secure partnerships that expand your portfolio’s market reach and impact.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Exclusive Industry Insights
                                </h3>
                                <p>
                                Interact with market leaders and decision-makers, gaining insights to refine your investment strategies and maximize returns for your stakeholders.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
