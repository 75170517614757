import React from 'react';

const EventPurpose = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white text-right">
                    <h2 className="text-3xl mr-10 mt-4 font-spartan font-semibold uppercase">
                        Why We’re Hosting This Event
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="p-6 ml-5 mr-4 bg-transparent text-gray-700">
                        <p className="text-lg leading-relaxed">
                            At the <strong>Capital Fundraising Summit</strong>, 
                            we are committed to fostering high-impact investment that reshapes industries and strengthens global progress. By providing a platform for royal family offices, we are supporting a legacy of positive change and empowering family offices to make their mark on the future. Your family office holds the power to drive transformative growth—let’s shape that future together.
                        </p>
                    </div>      
                    <div className="border-l-4 border-dashed mr-24 border-primaryGreen h-full"></div>
                </div>
            </div>
        </section>
    );
};

export default EventPurpose;
