import React from 'react';

const Benefits = () => {
    return (
        <section className="flex flex-col mx-4 sm:mx-6 md:mx-8 lg:mx-auto max-w-5xl mb-8 mt-4 sm:mt-6 md:mt-7">
            <div className="flex flex-col w-full shadow-sm rounded-lg overflow-hidden">
                {/* Header */}
                <div className="bg-[#25d366] p-4 sm:p-5 md:p-6 rounded-t-lg text-white">
                    <h2 className="text-2xl sm:text-2xl md:text-3xl font-semibold uppercase ml-3 sm:ml-6 md:ml-10 mt-2 sm:mt-3 md:mt-4">
                        What's in It for You?
                    </h2>
                </div>

                {/* Content Container */}
                <div className="flex flex-row relative">
                    {/* Vertical Line - Hidden on mobile */}
                    <div className="hidden sm:block absolute left-8 sm:left-12 md:left-24 top-0 bottom-0 border-l-4 border-dashed border-[#25d366]"></div>

                    {/* Benefits Content */}
                    <div className="flex-1 p-4 sm:p-5 md:p-6 sm:ml-16 md:ml-20 space-y-6 sm:space-y-8">
                        {/* Exposure */}
                        <div className="benefit-item">
                            <h3 className="text-xl sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">
                                Exposure
                            </h3>
                            <p className="text-gray-700 text-sm sm:text-base">
                                Present your venture fund to a curated audience of global investors, fund managers, and industry leaders.
                            </p>
                        </div>

                        {/* Investment Potential */}
                        <div className="benefit-item">
                            <h3 className="text-xl sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">
                                Investment Potential
                            </h3>
                            <p className="text-gray-700 text-sm sm:text-base">
                                Secure capital and partnerships from LPs and institutional investors seeking high-potential funds.
                            </p>
                        </div>

                        {/* Connections for Growth */}
                        <div className="benefit-item">
                            <h3 className="text-xl sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">
                                Connections for Growth
                            </h3>
                            <p className="text-gray-700 text-sm sm:text-base">
                                Meet key stakeholders who can open doors to new investment opportunities.
                            </p>
                        </div>

                        {/* Market Reach */}
                        <div className="benefit-item">
                            <h3 className="text-xl sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">
                                Market Reach
                            </h3>
                            <p className="text-gray-700 text-sm sm:text-base">
                                Access a network that can amplify your fund's influence and geographical reach.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Benefits;