import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Prime Access to Investment Networks
                                </h3>
                                <p>
                                Gain exposure to family offices, VCs, and institutional investors seeking partnerships with established corporations ready for innovation and expansion.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Strengthen Industry Positioning
                                </h3>
                                <p>
                                By presenting at CFS, you demonstrate your corporate commitment to pioneering growth, positioning your company as an industry leader among global investors.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Global Partnership Opportunities
                                </h3>
                                <p>
                                Collaborate with investors and industry leaders who can enable market expansion, joint ventures, and access to new technologies
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Exclusive Networking and Insightful Dialogue
                                </h3>
                                <p>
                                Engage with decision-makers, industry experts, and investors who provide insights into market trends and growth opportunities.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
