import React from 'react';
import backgroundImage from '../../Image/preview-4.webp';

const Introduction = () => {
  return (
    <section className="flex flex-col md:flex-row w-full h-96 bg-cover bg-center rounded-lg mb-24 shadow-2xl mt-20 mx-auto max-w-5xl"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      
      <div className="flex-1 hidden md:block" />
      <div className="flex-1 bg-gradient-to-r from-transparent to-orange-700 flex flex-col justify-center p-6 rounded-r-lg text-2xl">
        <p className="leading-relaxed font-spartan font-bold text-white">
        Is your corporation poised for expansion, innovation, or new ventures?
        </p>
        <p className="text-white font-spartan font-bold mt-2">
          The <strong className=''>Corporate Showcase at CFS </strong> provides a high-profile stage to present your strategic initiatives, products, and technology to investors and partners prepared to drive growth and transformation.
        </p>
      </div>
    </section>
  );
};

export default Introduction;
