import React from 'react';

const Benefits = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        What’s in It for You?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>

                    <div className="p-6 ml-20 bg-transparent text-gray-700 space-y-6">
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Exposure
                            </h3>
                            <p>
                            Showcase to international investors, corporations, and thought leaders.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Investment Potential
                            </h3>
                            <p>
                            Secure collaborations with funds and investors aligned with your national goals.

                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Connections for Growth
                            </h3>
                            <p>
                            Establish ties with partners who value impactful investments.

                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Market Reach
                            </h3>
                            <p>
                            Gain visibility across new regions, driving greater influence and investment.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Benefits;
