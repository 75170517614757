import React from 'react';

const HowItWorks = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-row w-full">
                <div className="flex flex-col justify-start w-full">
                    <div className="bg-[#25d366] p-6 rounded-tl-lg md:rounded-bl-none text-white text-right">
                        <h2 className="text-3xl mr-10 mt-4 font-spartan font-semibold uppercase">
                            How It Works
                        </h2>
                    </div>
                    <div className="flex flex-row">
                        <div className="p-6 bg-transparent text-gray-700 space-y-6 ml-5 mr-4">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Application Process
                                </h3>
                                <p>
                                Complete a streamlined application outlining your corporate venture initiatives and why they deserve a showcase at the Capital Fundraising Summit.

                                </p>
                            </div>

                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Selection
                                </h3>
                                <p>
                                Our expert panel will evaluate applicants based on their innovation, impact potential, and alignment with the summit’s mission. Only top corporate venture capital programs will be selected.
                                </p>
                            </div>

                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Main Stage Presentation
                                </h3>
                                <p>
                                If chosen, you’ll receive a 10-minute slot to present on our main stage. Showcase your initiatives and how they contribute to corporate innovation and industry transformation.

                                </p>
                            </div>
                        </div>
                        <div className="border-l-4 border-dashed mr-24 border-primaryGreen h-full"></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
