import React from 'react';
import backgroundImage from '../../Image/preview-8.webp';

const Introduction = () => {
  return (
    <section className="flex flex-col md:flex-row w-full h-96 bg-cover bg-center rounded-lg mb-24 shadow-2xl mt-20 mx-auto max-w-5xl"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      
      <div className="flex-1 hidden md:block" />
      <div className="flex-1 bg-gradient-to-l from-transparent to-orange-700 flex flex-col justify-center p-6 rounded-r-lg text-2xl">
        <p className="leading-relaxed font-spartan font-bold text-white">
        Does your private equity fund aim to elevate portfolio companies, drive market leadership, and expand into new geographies?
        </p>
        <p className="text-white font-spartan font-bold mt-2">
          The <strong className=''>The Private Equity Showcase at CFS</strong> provides a dedicated platform for PE funds to present their investment strategies, attracting institutional investors and partners ready to propel high-growth enterprises.
        </p>
      </div>
    </section>
  );
};

export default Introduction;
