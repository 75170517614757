import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Image/CAPITAL FUNDRAISING SUMMIT.png';

function Navbar() {
    return (
        <nav className="bg-primaryGreen font-spartan text-white px-6 flex items-center justify-between py-4">
            <div className="flex items-center space-x-2">
                <Link to="/">
                    <img src={logo} alt="Logo" className="rounded-full h-1/6 w-1/6" />
                </Link>
            </div>
            <div className="flex items-center space-x-6">
                <a href="/" className="hover:text-darkerGreen text-2xl transition-colors">
                    Menu
                </a>
                <Link 
                    to="https://docs.google.com/forms/d/e/1FAIpQLSdac3vjzdFdsFD95syDiHZSsEJ2xi1MWX3cyCqsPab7hReR1Q/viewform" 
                    className="bg-white text-primaryGreen px-6 py-2 rounded-full font-bold hover:bg-darkerGreen hover:text-white transition-colors duration-300"
                >
                    Apply Now
                </Link>
            </div>
        </nav>
    );
}

export default Navbar;