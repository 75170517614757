import React from 'react';
import backgroundImage from '../../Image/ppreview-1.webp';

const VCMarketingSection = () => {
  return (
    <section className="flex flex-col md:flex-row w-full h-96 bg-cover bg-center rounded-lg mb-24 shadow-2xl mt-20 mx-auto max-w-5xl"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      
      <div className="flex-1 hidden md:block" />
      <div className="flex-1 bg-gradient-to-r from-transparent to-orange-700 flex flex-col justify-center p-6 rounded-r-lg text-2xl">
        <p className="leading-relaxed font-spartan font-bold text-white">
          Are you part of a venture capital firm ready to connect with high-impact investors, drive new partnerships, and uncover valuable opportunities in the global market?
        </p>
        <p className="text-white font-spartan font-bold mt-2">
          The <strong className=''>Capital Fundraising Summit (CFS)</strong> provides a unique platform for VCs to showcase their strategies and unlock capital for pioneering investments.
        </p>
      </div>
    </section>
  );
};

export default VCMarketingSection;
