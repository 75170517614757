import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Targeted Exposure to Investment Leaders
                                </h3>
                                <p>
                                Gain visibility among institutional investors and family offices interested in corporate-backed venture initiatives that align with emerging market needs.

                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Accelerate Strategic Collaborations
                                </h3>
                                <p>
                                Meet potential partners and investors who can boost your corporate venture initiatives, opening doors to fresh innovations and strategic alliances.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Investment Confidence
                                </h3>
                                <p>
                                Being featured at CFS signals to the investment community that your corporate venture program is positioned for global growth and high returns.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Exclusive Networking and Learning
                                </h3>
                                <p>
                                Engage with experts who share insights on best practices for corporate investment, market expansion, and innovation leadership.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
