import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Direct Access to Strategic Investors
                                </h3>
                                <p>
                                    Present large-scale initiatives directly to high-net-worth individuals, sovereign wealth funds, and corporates looking for impactful partnerships.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Spotlight on National Development Projects
                                </h3>
                                <p>
                                    Elevate your project’s visibility, gaining critical support for infrastructure, technology, and economic development initiatives that align with investor interests.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Foster Public-Private Collaboration
                                </h3>
                                <p>
                                    CFS brings together government leaders and private investors, creating a unique opportunity for partnerships that drive regional and national progress.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Connect with Global Policy Leaders
                                </h3>
                                <p>
                                    Join influential discussions with policymakers, investors, and industry leaders, sharing insights that shape the future of government-led innovation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
