import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Direct Access to Global Ventures
                                </h3>
                                <p>
                                Meet with entrepreneurs, fund managers, and corporate leaders ready to collaborate on projects that align with your country’s economic development goals.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Strengthen Partnerships for National Progress
                                </h3>
                                <p>
                                Leverage CFS’s global network to establish impactful public-private partnerships, drawing resources and expertise for sustainable growth.

                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Drive Economic Diversification
                                </h3>
                                <p>
                                The summit’s range of industries offers diverse opportunities, from technology and infrastructure to sustainable ventures, enabling your fund to diversify across high-potential sectors.

                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Prestige and Global Recognition
                                </h3>
                                <p>
                                Showcasing at CFS highlights your fund’s leadership in shaping the future of global investment, attracting respect and interest from a worldwide audience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
