import React from 'react';
import backgroundImage from '../../Image/preview-9.jpg';

const Introduction = () => {
  return (
    <section className="flex flex-col md:flex-row w-full h-auto bg-cover bg-center rounded-lg mb-24 shadow-2xl mt-20 mx-auto max-w-5xl"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      
      <div className="flex-1 hidden md:block" />
      <div className="flex-1 bg-gradient-to-l from-transparent to-orange-700 flex flex-col justify-center p-6 rounded-r-lg text-2xl">
        <p className="leading-relaxed font-spartan font-bold text-gray-950">
        Are you leading a unicorn—a pioneering startup on the verge of reshaping industries globally? Do you have an innovation that’s ready for the world stage?
        </p>
        <p className="text-gray-950 font-spartan font-bold mt-2">
          The <strong className=''>Capital Fundraising Summit </strong> is your chance to present your disruptive technology and game-changing potential to a distinguished audience of venture capitalists, sovereign wealth funds, family offices, policymakers, and international investors.
        </p>
      </div>
    </section>
  );
};

export default Introduction;
