import React from 'react';

const Benefits = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        What’s in It for You?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>

                    <div className="p-6 ml-20 bg-transparent text-gray-700 space-y-6">
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Exposure
                            </h3>
                            <p>
                            Present your fund to a targeted audience of investors and strategic partners.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Investment Potential
                            </h3>
                            <p>
                            Secure partnerships and capital to support ambitious portfolio growth.

                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Connections for Growth
                            </h3>
                            <p>
                            Meet key decision-makers who can accelerate your fund’s success.

                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Market Reach
                            </h3>
                            <p>
                            Expand into new sectors and regions that align with your fund’s strategy.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Benefits;
