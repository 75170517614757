import React, { useEffect, useRef } from 'react';
import Introduction from './Introduction';
import WhyShowcase from './WhyShowcase';
import HowItWorks from './HowItWorks';
import Benefits from './Benefits';
import EventPurpose from './EventPurpose';
import CallToAction from './CallToAction';

const Ffscfs = () => {
    const sectionRefs = useRef([]);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.remove('opacity-0', 'translate-y-10');
                        entry.target.classList.add('opacity-100', 'translate-y-0');
                    }
                });
            },
            { threshold: 0.3 }
        );
        sectionRefs.current.forEach((section) => {
            if (section) observer.observe(section);
        });
        return () => observer.disconnect();
    }, []);
    return (
        <div className="">
            <div
                ref={(el) => (sectionRefs.current[0] = el)}
                className="opacity-0 translate-y-10 transition-opacity duration-700 ease-out"
            >
                <Introduction />
            </div>
            <div
                ref={(el) => (sectionRefs.current[1] = el)}
                className="opacity-0 translate-y-10 transition-opacity duration-700 ease-out"
            >
                <WhyShowcase />
            </div>
            <div
                ref={(el) => (sectionRefs.current[2] = el)}
                className="opacity-0 translate-y-10 transition-opacity duration-700 ease-out"
            >
                <HowItWorks />
            </div>
            <div
                ref={(el) => (sectionRefs.current[3] = el)}
                className="opacity-0 translate-y-10 transition-opacity duration-700 ease-out"
            >
                <Benefits />
            </div>
            <div
                ref={(el) => (sectionRefs.current[4] = el)}
                className="opacity-0 translate-y-10 transition-opacity duration-700 ease-out"
            >
                <EventPurpose />
            </div>
            <div
                ref={(el) => (sectionRefs.current[5] = el)}
                className="opacity-0 translate-y-10 transition-opacity duration-700 ease-out"
            >
                <CallToAction />
            </div>
            <div className="flex justify-center my-8">
                <a
                    href="https://forms.gle/rnmDRZMbpCMXjSSx9"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-primaryGreen text-white font-bold py-3 px-6 text-lg rounded shadow inline-block text-center animate-flash"
                >
                    Apply Now
                </a>
            </div>

        </div>
    );
};

export default Ffscfs;
