import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Unmatched Investor Visibility
                                </h3>
                                <p>
                                    Present to institutional investors, family offices, and sovereign wealth funds looking for impactful, innovative portfolios. Gain recognition from the very entities influencing the future of investment.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Networking with Global Leaders
                                </h3>
                                <p>
                                    CFS attracts thought leaders, fund managers, and policymakers from around the world, creating invaluable connections that can accelerate your firm’s growth.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Direct Access to Funding
                                </h3>
                                <p>
                                    Engage with high-net-worth individuals and investment decision-makers seeking visionary VCs to partner with.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Strengthen Your Credibility
                                </h3>
                                <p>
                                    Showcasing at CFS solidifies your firm’s position as a thought leader in venture capital, drawing credibility and confidence from global stakeholders.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
