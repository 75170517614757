import React from 'react';

const Benefits = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-12 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        What’s in It for You?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>

                    <div className="p-6 ml-20 bg-transparent text-gray-700 space-y-6">
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Exclusive Exposure
                            </h3>
                            <p>
                                Present your family office’s vision to a curated audience of global investors, thought leaders, and industry pioneers.

                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Investment Potential
                            </h3>
                            <p>
                                Unlock opportunities to align with transformative projects and collaborations for a future-oriented portfolio.

                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Connections for Growth
                            </h3>
                            <p>
                                Meet decision-makers who can help accelerate the growth of your family’s influence through strategic partnerships.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Market Reach
                            </h3>
                            <p>
                                Access new markets, regions, and networks that amplify your family office's values and global impact.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Benefits;
