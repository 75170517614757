import React from 'react';
import backgroundImage from '../../Image/preview-2.webp';

const Introduction = () => {
  return (
    <section className="flex flex-col md:flex-row w-full h-96 bg-cover bg-center rounded-lg mb-24 shadow-2xl mt-20 mx-auto max-w-5xl"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      
      <div className="flex-1 hidden md:block" />
      <div className="flex-1 bg-gradient-to-l from-transparent to-orange-600 flex flex-col justify-center p-6 rounded-r-lg text-2xl">
        <p className="leading-relaxed font-spartan font-bold text-gray-950">
        Does your corporate venture arm aim to engage with startups and investors who can propel your innovation agenda?
        </p>
        <p className="font-spartan font-bold mt-2 text-gray-950">
          The <strong className=''>CFS </strong> Corporate Venture Capitalist Showcase offers a prime opportunity to present your portfolio to a select audience ready to invest in and collaborate on disruptive innovations.
        </p>
      </div>
    </section>
  );
};

export default Introduction;
