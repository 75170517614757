import React from 'react';
import backgroundImage from '../../Image/preview-5.webp';

const Introduction = () => {
  return (
    <section className="flex flex-col md:flex-row w-full h-96 bg-cover bg-center rounded-lg mb-24 shadow-2xl mt-20 mx-auto max-w-5xl"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      
      <div className="flex-1 hidden md:block" />
      <div className="flex-1 bg-gradient-to-l from-transparent to-orange-700 flex flex-col justify-center p-6 rounded-r-lg text-2xl">
        <p className="leading-relaxed font-spartan font-bold text-gray-950">
        Are you representing a government project or public-private partnership with transformative potential?
        </p>
        <p className="text-gray-950 font-spartan font-bold mt-2">
          The <strong className=''>Government Showcase at CFS</strong> offers an exclusive platform to connect with investors and corporations interested in driving economic development through strategic
        </p>
      </div>
    </section>
  );
};

export default Introduction;
