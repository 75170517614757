import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Unparalleled Access to Capital
                                </h3>
                                <p>
                                Gain exposure to asset managers, family offices, and sovereign wealth funds actively looking to collaborate with fund-of-funds strategies that drive sustained growth and diversification.

                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Network with Leading VCs and PEs
                                </h3>
                                <p>
                                Engage with venture capital and private equity leaders, establishing relationships with funds that align with your investment vision and amplify your impact.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Global Market Intelligence
                                </h3>
                                <p>
                                CFS’s platform provides invaluable insights into emerging market trends, equipping you with knowledge to refine strategies and capitalize on high-growth sectors.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                Exclusive Visibility and Credibility
                                </h3>
                                <p>
                                Showcasing at CFS endorses your fund as a credible, high-potential investment, attracting attention from respected stakeholders across the investment ecosystem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
