import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import vcShowcasImage from '../Image/ppreview-1.webp'
import cvcShowcasImage from '../Image/preview-2.webp'
import foscfsImage from '../Image/preview-3.webp'
import cscfsImage from '../Image/preview-4.webp'
import goscfsImage from '../Image/preview-5.webp'
import ffscfsImage from '../Image/preview-6.webp'
import swfscfsImage from '../Image/preview-7.webp'
import pefscfsImage from '../Image/preview-8.webp'
import uyupcfsImage from '../Image/preview-9.jpg'
import image10 from '../Image/preview-10.jpg'

const ShowcaseCard = ({ image, title, description, link, isReverse, sectionRef }) => {
  return (
    <div 
      ref={sectionRef}
      className={`flex flex-col ${isReverse ? 'md:flex-row-reverse' : 'md:flex-row'} 
        bg-transparent text-black rounded-lg mb-8
        mx-4 sm:mx-6 md:mx-16 lg:mx-auto max-w-5xl
        opacity-0 translate-y-10 transition-all duration-1000 ease-linear
        hover:shadow-lg`}
    >
      <div className="w-full md:w-1/4 h-48 md:h-auto">
        <img 
          src={image} 
          alt={title}
          className={`w-full h-full object-cover
            ${isReverse 
              ? 'rounded-t-lg md:rounded-r-lg md:rounded-l-none' 
              : 'rounded-t-lg md:rounded-l-lg md:rounded-r-none'}`}
        />
      </div>

      <div className="flex flex-col justify-start flex-1">
        <div className={`bg-[#25d366] p-4 sm:p-6
          ${isReverse 
            ? 'rounded-tl-lg md:rounded-br-none' 
            : 'rounded-tr-lg md:rounded-bl-none'}
          text-white`}
        >
          <Link to={link} className="group">
            <h2 className="text-2xl sm:text-2xl md:text-3xl 
              ml-2 sm:ml-4 mt-2
              font-spartan font-semibold uppercase 
              transition-all duration-300 group-hover:ml-6">
              {title}
            </h2>
          </Link>
        </div>

        <div className="p-4 sm:p-6 bg-transparent">
          <p className="text-base sm:text-lg leading-relaxed font-spartan">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

const MainPage = () => {
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.remove('opacity-0', 'translate-y-10');
            entry.target.classList.add('opacity-100', 'translate-y-0');
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  const showcases = [
    {
      image: vcShowcasImage,
      title: "VC Showcase",
      description: "Are you part of a venture capital firm ready to connect with high-impact investors, drive new partnerships, and uncover valuable opportunities in the global market?",
      link: "/VCMarketingSection"
    },
    {
      image: cvcShowcasImage,
      title: "Corporate Venture Capitalist Showcase",
      description: "Does your corporate venture arm aim to engage with startups and investors who can propel your innovation agenda?",
      link: "/Cvcscfs"
    },
    {
      image: foscfsImage,
      title: "Family Offices Showcase",
      description: "Does your family office seek to align with transformative technologies and impactful investments that echo your values and legacy?",
      link: "/Foscfs"
    },
    {
      image: cscfsImage,
      title: "Corporate Showcase",
      description: "Is your corporation poised for expansion, innovation, or new ventures?",
      link: "/Cscfs"
    },
    {
      image: goscfsImage,
      title: "Government Officials Showcase",
      description: "Are you representing a government project or public-private partnership with transformative potential?",
      link: "/Goscfs"
    },
    {
      image: ffscfsImage,
      title: "Fund of Funds Showcase",
      description: "Is your fund of funds positioned to catalyze growth by investing in top-performing venture capital and private equity funds?",
      link: "/Ffscfs"
    },
    {
      image: swfscfsImage,
      title: "Sovereign Wealth Funds Showcase",
      description: "Are you representing a sovereign wealth fund looking to amplify national development and secure high-yield global investments?",
      link: "/Swfscfs"
    },
    {
      image: pefscfsImage,
      title: "Private Equity Funds Showcase",
      description: "Does your private equity fund aim to elevate portfolio companies, drive market leadership, and expand into new geographies?",
      link: "/Pefscfs"
    },
    {
      image: uyupcfsImage,
      title: "Unleash Your Unicorn's Potential",
      description: "Are you leading a unicorn—a pioneering startup on the verge of reshaping industries globally? Do you have an innovation that's ready for the world stage?",
      link: "/Uyupcfs"
    },
    {
      image: image10,
      title: "Corporate Showcase",
      description: "Showcase your corporate ventures and explore synergies with innovative companies and investors.",
      link: "/Rfs"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 px-4 sm:px-6 md:px-8 py-6 sm:py-8 md:py-10">
      <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold font-spartan 
        text-darkerGreen mt-3 sm:mt-4 md:mt-5 mb-6 sm:mb-8 
        text-center md:text-left md:ml-8 lg:ml-12">
        Capital Fundraising Summit Showcases
      </h1>

      <div className="max-w-7xl mx-auto">
        {showcases.map((showcase, index) => (
          <ShowcaseCard
            key={index}
            {...showcase}
            isReverse={index % 2 !== 0}
            sectionRef={(el) => (sectionRefs.current[index + 1] = el)}
          />
        ))}
      </div>

      <div className="flex justify-center my-8 sm:my-10 md:my-12">
        <a
          href="https://forms.gle/rnmDRZMbpCMXjSSx9"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#25d366] text-white font-bold 
            py-3 px-6 text-base sm:text-lg
            rounded shadow-lg hover:shadow-xl
            transform hover:-translate-y-1 
            transition-all duration-300
            animate-pulse hover:animate-none"
        >
          Apply Now
        </a>
      </div>
    </div>
  );
};

export default MainPage;