import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Global Recognition
                                </h3>
                                <p>
                                    As one of the world’s premier investment summits, the Capital Fundraising Summit offers exclusive visibility to asset managers and investors controlling trillions in capital. Presenting on this prestigious stage shines a spotlight on your unicorn, drawing attention from the global investment community.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    High-Impact Networking
                                </h3>
                                <p>
                                    Gain access to powerful connections with decision-makers, thought leaders, and strategic partners who can accelerate your unicorn’s growth and market impact.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Market Expansion Opportunities
                                </h3>
                                <p>
                                    The summit’s global reach introduces your innovation to new regions, markets, and potential revenue streams, allowing you to scale in ways you may not have previously considered.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Access to Investment
                                </h3>
                                <p>
                                    Present directly to fund managers and institutional investors actively looking for breakthrough technologies. This summit is a streamlined path to the critical funding needed to scale your unicorn.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Credibility with Top Investors
                                </h3>
                                <p>
                                    Being featured at the Capital Fundraising Summit signals high investor confidence. This endorsement can boost your credibility, instilling trust in additional investors as you continue to grow.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Validation from Industry Leaders
                                </h3>
                                <p>
                                    Participation in such a reputable event serves as validation of your unicorn’s vision and impact. This acknowledgment from global decision-makers and influencers speaks volumes about your technology’s significance.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Showcase Innovation
                                </h3>
                                <p>
                                    Position your unicorn as an industry innovator, a true game-changer with the potential to disrupt and redefine markets. This is the place to amplify your role in the future of industry.

                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Insights and Market Intelligence

                                </h3>
                                <p>
                                    Interact with a diverse audience, including investors and market leaders, to refine your strategy based on real-time feedback and investor insights.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Extensive Media Coverage
                                </h3>
                                <p>
                                    Benefit from global media attention, expanding your unicorn’s reach and influence. The event attracts international outlets eager to cover groundbreaking technologies.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Strategic Networking with Industry Giants
                                </h3>
                                <p>
                                    Forge alliances with influential stakeholders who can propel your unicorn through partnerships and transformative collaborations, maximizing growth and future impact.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
