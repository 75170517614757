import React from 'react';

const WhyShowcase = () => {
    return (
        <section className="flex flex-col md:flex-row bg-transparent text-black rounded-lg mb-0 mt-7 mx-auto max-w-5xl">
            <div className="flex flex-col justify-start w-full">
                <div className="bg-[#25d366] p-6 rounded-tr-lg md:rounded-bl-none text-white">
                    <h2 className="text-3xl ml-10 mt-4 font-spartan font-semibold uppercase">
                        Why Showcase at the Capital Fundraising Summit?
                    </h2>
                </div>
                <div className="flex flex-row">
                    <div className="border-l-4 border-dashed ml-24 border-primaryGreen h-full"></div>
                    <div className="p-6 ml-20 bg-transparent">
                        <div className="space-y-6 text-gray-700">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Exclusive Access to High-Growth Opportunities
                                </h3>
                                <p>
                                    Present directly to selected startups and VCs, curating investments that align with your family’s vision for impactful growth
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Enhanced Market Access and Global Reach
                                </h3>
                                <p>
                                    Leverage CFS’s international network to access new regions and emerging markets, bringing fresh opportunities to diversify and expand your portfolio.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Credibility and Industry Recognition
                                </h3>
                                <p>
                                    CFS showcases prominent family offices committed to leading sustainable, innovation-focused investment. Gain credibility and visibility among other esteemed investors.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Connect with Likeminded Investors
                                </h3>
                                <p>
                                    Meet family offices and HNWIs focused on strategic, long-term growth, fostering alliances that align with your investment philosophy
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyShowcase;
