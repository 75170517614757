import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import VCMarketingSection from './components/1ST_VCSCFS/VCShowcase';
import Cvcscfs from './components/2ND_CVCSCFS/Cvcscfs';
import Foscfs from './components/3RD_FOSCFS/Foscfs'
import Cscfs from './components/4TH_CSCFS/Cscfs'
import Goscfs from './components/5TH_GOSCFS/Goscfs'
import Ffscfs from './components/6TH_FFSCFS/Ffscfs'
import Swfscfs from './components/7TH_SWFSCFS/Swfscfs'
import Pefscfs from './components/8TH_PEFSCFS/Pefscfs'
import Uyupcfs from './components/9TH_UYUPCFS/Uyupcfs'
import Rfs from './components/10TH_RFS/Rfs'
import Navbar from './components/Navbar';
import MainPage from './components/mainPage'
function App() {
    return (
        <div className="App">
            <BrowserRouter>
            <Navbar />
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/VCMarketingSection" element={<VCMarketingSection />} />
                <Route path="/Cvcscfs" element={<Cvcscfs />} />
                <Route path="/Foscfs" element={<Foscfs />} />
                <Route path="/Cscfs" element={<Cscfs />} />
                <Route path="/Goscfs" element={<Goscfs />} />
                <Route path="/Ffscfs" element={<Ffscfs />} />
                <Route path="/Swfscfs" element={<Swfscfs />} />
                <Route path="/Pefscfs" element={<Pefscfs />} />
                <Route path="/Uyupcfs" element={<Uyupcfs />} />
                <Route path="/Rfs" element={<Rfs />} />
              </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
